<template>
  <div class="container-fluid mt-5">
    <div class="row">
      <div class="col-xl-12 order-xl-1">
        <card>
          <div slot="header" class="row align-items-center">
            <div class="col-8">
              <h3 class="mb-0">{{ $t("RESELLERS.EDIT_RESELLER") }}</h3>
            </div>
            <div class="col-4 text-right">
              <base-button
                @click="goBack"
                type="button"
                class="btn btn-sm btn-primary"
              >
                {{ $t("COMMON.RETURN_TO_LIST") }}
              </base-button>
            </div>
          </div>
          <div class="card-body">
            <reseller-form
              :loading="loading"
              :resellerData="reseller"
              :formErrors="formErrors"
              @resellerSubmitted="handleSubmit"
              @formChanged="() => (alertLeave = true)"
            />
          </div>
        </card>
      </div>
    </div>
  </div>
</template>

<script>
import ResellerForm from "./partials/ResellerForm.vue";
import { cloneDeep } from "lodash";
import alertLeave from "@/mixins/alert-leave-mixin";
import defaultReseller from "./defaultReseller";

export default {
  layout: "DashboardLayout",

  components: { ResellerForm },
  mixins: [alertLeave],

  data() {
    return {
      reseller: cloneDeep(defaultReseller),
      formErrors: null,
      loading: false,
    };
  },

  created() {
    this.get();
  },

  methods: {
    async get() {
      this.loading = true;
      try {
        const id = this.$route.params.id;
        await this.$store.dispatch("resellers/get", id);
        this.reseller = this.$store.getters["resellers/reseller"];
        this.loading = false;
      } catch (error) {
        this.$notify({
          type: "danger",
          message: `Oops, something went wrong!`,
        });
        this.loading = false;
      }
    },

    goBack() {
      this.$router.push({ name: "List Resellers" });
    },

    viewReseller() {
      this.alertLeave = false;
      this.$router.push({
        name: "View Reseller",
        params: { id: this.reseller.id },
      });
    },

    async handleSubmit(reseller) {
      this.loading = true;
      const resellerData = cloneDeep(reseller);

      try {
        await this.$store.dispatch("resellers/update", resellerData);
        this.$notify({
          type: "success",
          message: this.$t("RESELLER.RESELLER_UPDATED"),
        });
        this.viewReseller();
        this.loading = false;
      } catch (error) {
        this.$notify({
          type: "danger",
          message: this.$t("ERRORS.SOMETHING_WENT_WRONG"),
        });
        this.formErrors = error.response.data.errors;
        this.loading = false;
      }
    },
  },
};
</script>
